// These fields will be shown with JS.
.userecho-custom .custom-field {
  &.cf_1, &.cf_2, &.cf_4, &.cf_5 {
    margin: 0 0 1rem 0;

    .custom-field-name {
      display: none;
    }
  }
}

.btn {
  position: relative;

  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: -1px;
    border-radius: .25rem;
    box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15) !important;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      &:after {
        box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.15) !important;
      }
    }
  }
}

.btn-block {
  display: block;
}

.btn-primary {
  color: #fff !important;
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #d12345 !important;
  border-color: #d12345 !important;
}

.btn-info {
  color: #fff !important;
  background-color: #3498db !important;
  border-color: #3498db !important;
}

.ue-content p {
  margin: 0 0 1.5rem 0 !important;
}

.ue-content p:last-child {
  margin: 0 !important;
}

.ue-editor p br:last-child:first-child {
  display: inline;
}

.ue-tab-container {
  display: none;
}

@media (min-width: 992px) {
  .ue-tab-container {
    display: block;
  }
}